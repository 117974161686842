<template>
  <footer class="footer" :style="footerStyles" id="footer">
    <div class="container footer__container">
      <div class="footer__info">
        <h4 class="heading-1">
          {{ $t(footer.title) }}
        </h4>
        <div class="footer__content">
          <p>{{ $t("EMAIL") }}: {{ footer.email }}</p>
        </div>
        <ul class="footer__social">
          <li
            class="footer__icon"
            v-for="(item, index) in footer.social"
            :key="index"
          >
            <a :href="item.url">
              <component :is="item.icon" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import IconFacebook from "@/assets/icons/facebook.svg";
import IconGithub from "@/assets/icons/github.svg";
import IconLinkedin from "@/assets/icons/linkedin.svg";
import IconInstagram from "@/assets/icons/instagram.svg";

export default {
  components: {
    IconFacebook,
    IconGithub,
    IconLinkedin,
    IconInstagram,
  },
  data() {
    const backgroundImage =
      "https://res.cloudinary.com/simplecoder/image/upload/v1614048094/portfolio/WhatsApp_Image_2021-02-22_at_9.38.00_PM.jpg";
    return {
      footerStyles: {
        "background-image": `linear-gradient(to right bottom, rgba(16, 26, 38, .9), rgba(42, 55, 64, 0.88)), url("${backgroundImage}")`,
      },
      footer: {
        title: "Get in touch",
        formTitle: "or just write me an email here",
        phone: "+1 514 850 1467",
        email: "hi@pacheco.io",
        social: [
          {
            url: "https://www.facebook.com/pacheco.io",
            icon: "IconFacebook",
          },
          {
            url: "https://www.linkedin.com/in/pachecothiago/",
            icon: "IconLinkedin",
          },
          {
            url: "https://github.com/pachecoio",
            icon: "IconGithub",
          },
          {
            url: "https://www.instagram.com/pacheco.io/",
            icon: "IconInstagram",
          },
        ],
      },
      form: {},
    };
  },
};
</script>

<style></style>
