export default {
  // Header
  Home: "Home",
  Résumé: "Résumé",
  Portfolio: "Portfolio",
  Contact: "Contact",
  // Hero
  age: "age",
  phone: "téléphone",
  email: "courriel",
  "FullStack Developer | Mobile Developer": "Développeur FullStack | Développeur Mobile",
  // About
  "Download CV": "Telecharcher CV",
  Hi: "Salut",
  "I am Full Stack Developer able to build Web and mobile applications from the ground up - from concept, navigation, layout and programming to UX and SEO. Skilled at writing well-designed, testable and efficient code using current best practices. Fast learner, hard worker and team player who is proficient in an array of languages and tools. Passionate about working with Python, Javascript and its latest frameworks and I am a big enthusiast working with Flutter on mobile development.":
    "Je suis un Développeur Full Stack capable de créer applications Web et mobile à partir de la base - du concept à la navigation, la mise en page et la programmation à l'UX et au SEO. Habile à rédiger un code bien conçu, testable et efficace, en utilisant les meilleures pratiques actuelles en matière de développement Web. Apprenti rapide, travailleur acharné et joueur d'équipe qui maîtrise un grand nombre de langages Web e mobile. Passionné par le travail avec Python, Javascript et ses derniers frameworks et un grand enthousiaste travaillant avec Flutter sur le développement mobile.",
  // Résumé
  "<p>I am a Developer with 5+ years of experience that have worked with many different technologies and environments.  Experienced with back-end as well as front-end development, I am a professional passionate about creating good solutions and bringing amazing designs to life.</p><p>I started my career working with IT support, which gave me a good experience with infrastructures and network management, improving my DevOps skills. But soon I changed to a developer role, where I started applying and improving my coding skills.</p><p>Throughout my career as a developer, I had worked in many different fields such as: Health Care, Digital Agencies, Software Factories and University. I had the opportunity to architect, develop and maintain web and mobile applications, working with different teams and leading some projects and features.</p>":
    "<p>Je suis un développeur avec 5+ ans d’experience qui a travaillé avec plusieurs technologies et environnements. J’ai bonnes expériences avec Back-end, Front-end et Mobile et je suis passionné par la création de bonnes solutions et la réalisation de designs incroyables.</p><p>J'ai commencé ma carrière en travaillant avec le support informatique, ce qui m'a donné une bonne expérience des infrastructures et de la gestion de réseau, mais dès que j'en ai eu la chance, j'ai changé pour un poste de développeur dans lequel j'avais de l'expérience en développement d'applications Web et mobiles.</p><p>Tout au long de ma carrière de développeur, j'ai travaillé dans de nombreux domaines tels que: la Santé, les Digital Agencies, les usines de logiciels et l’éducation. J'ai eu l'opportunité d'architecte, de développer et de maintenir des applications web et mobiles, de travailler avec différentes équipes et de diriger certains projets et fonctionnalités.</p>",
  "Software Development and Analysis": "Développement de Logiciels",
  "Alayacare is a Home Care Software company that offers a solution for agencies to manage clients, visits, accounting and many other functionalities. I am responsible for taking care of back-end as well as front-end development, creating new features and improving existing ones.":
    "AlayaCare est un fournisseur de logiciel révolutionnaire de soins de santé à domicile basé sur le nuage. Je suis responsable pour le développement back-end et front-end, de la création de nouvelles fonctionnalités et de l'amélioration de celles existantes.",
  "Sweepy is a mobile app soon to be released with the purpose of ordering food, reserving tables at restaurants and paying for parking in advance. I was responsible for the defining the architecture of the application from scratch, developing the infraestructure, full-stack and mobile development.":
    "Sweepy est une application mobile dans le but de commander de la nourriture, de réserver des tables dans les restaurants et de payer le stationnement à l'avance. J'étais responsable de la définition de l'architecture de l'application à partir de zéro, du développement de l'infrastructure, du full-stack et du développement mobile.",
  "A company focused on automation of all kinds, searching for improving efficiency and speed of current manual tasks replacing them with software automated tasks. I was responsible for creating a mobile app and automating the process of scanning invoices and work orders.":
    "Une entreprise axée sur l'automatisation de toutes sortes, cherchant à améliorer l'efficacité et la rapidité des tâches manuelles actuelles en les remplaçant par des tâches automatisées par logiciel. J'étais responsable de la création d'une application mobile et de l'automatisation du processus de numérisation des factures et des bons de travail.",
  Developer: "Développement de Logiciels",
  "Full Stack Developer ": "Développeur Full Stack",
  "The company is a software studio that creates, design and develops web and mobile applications. I worked in a team of designers and developers, where I was responsible for integrating software with third part solutions, develop and maintain web and mobile applications and configure cloud servers. Experience with a variety of technologies, working mostly with javascript and with the following stack: Nodejs, Reactjs, MongoDB and Dart/Flutter.":
    "L'entreprise est un studio de logiciels qui crée, conçoit et développe des applications Web et mobiles. J'ai travaillé au sein d'une équipe de concepteurs et de développeurs. J'étais responsable de l'intégration des logiciels avec des solutions tierces, du développement et de la maintenance d'applications Web et mobiles, ainsi que de la configuration de serveurs cloud. Expérience avec diverses technologies, en travaillant principalement avec javascript et avec les piles suivantes: Nodejs, Reactjs, MongoDB et Dart / Flutter.",
  "Web Developer": "Développeur Web",
  "Santa Cruz is a University in Paraná - Brazil maintained by the Paranaense Union of Education and Culture - UNIPEC. I was responsible for the creation, maintenance and support of web applications and integration with third part services, working close to the marketing team. Responsible for the creation of pages, email marketing and system support, working with PHP, Wordpress, HTML, CSS, Javascript, React.js and MySQL.":
    "Santa Cruz est un collège du Paraná - Brésil géré par l'Union de l'éducation et de la culture du Paraná - UNIPEC. J'étais responsable de la création, de la maintenance et du support des applications Web et de l'intégration avec des services tiers, en travaillant avec l'équipe marketing. Responsable de la création de pages, du marketing par courriel et du support système, travaillant avec PHP, Wordpress, HTML, CSS, Javascript, React.js et MySQL.",
  "HouseCricket is a brazilian digital agency specialized in digital marketing and communication strategies. I worked as a Web Developer alongside with the design team, responsible for creating and maintaining web applications and email marketing, creating web pages, email marketing templates and managing CMSs, working with PHP, HTML, CSS, Javascript (JQuery, React.js and Vue.js) and MySQL.":
    "HouseCricket est une agence digital brésilienne spécialisée dans les stratégies de marketing et de communication digital. J'ai travaillé en tant que développeur Web aux côtés de l'équipe de conception, responsable de la création et de la maintenance d'applications Web et de marketing par courrier électronique, de la création de pages Web, de modèles de marketing par courrier électronique et de la gestion de CMS, en travaillant avec PHP, HTML, CSS, Vue.js et MySQL.",
  "IT Support Assistant": "Assistant de Support Informatique",
  "Opusmultipla is a brazilian marketing agency part of a group called GrupoOM. I worked as an IT Support Assistant, responsible for providing support to computer operations, infrastructure and network management, ensuring that the technology services were delivered smoothly.":
    "Opusmultipla est une agence de marketing brésilienne appartenant au groupe GrupoOM. J'ai travaillé en tant qu'assistant de support informatique, chargé de fournir un soutien aux opérations informatiques, à l'infrastructure et à la gestion de réseau, en veillant à ce que les services technologiques soient fournis en douceur.",
  // Footer
  PHONE: "TÉLÉPHONE",
  EMAIL: "COURRIEL",
  "Get in touch": "Contactez moi",
  "or just write me an email here": "or écrivez-moi un email ici",
  "Your name": "Votre nom",
  "Your email": "Votre addresse courriel",
  "Message...": "Message...",
  "send": "envoyer",
  // Blog
  Search: "Search",
  // Comments
  Comments: "Comments",
};
