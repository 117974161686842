export default {
  // Header
  Home: "Home",
  Résumé: "Curriculum",
  Portfolio: "Portifólio",
  Contact: "Contato",
  // Hero
  age: "idade",
  phone: "phone",
  email: "e-mail",
  "FullStack Developer | Mobile Developer": "Desenvolvedor FullStack | Desenvolvedor Mobile",
  // About
  "Download CV": "Baixar CV",
  Hi: "Olá",
  "I am Full Stack Developer able to build Web and mobile applications from the ground up - from concept, navigation, layout and programming to UX and SEO. Skilled at writing well-designed, testable and efficient code using current best practices. Fast learner, hard worker and team player who is proficient in an array of languages and tools. Passionate about working with Python, Javascript and its latest frameworks and I am a big enthusiast working with Flutter on mobile development.":
    "Eu sou desenvolvedor capaz de construir uma presença na Web e mobile completa - desde conceito, navegação, layout e programação até UX e SEO. Boas habilidades em escrever códigos bem organizados, testáveis e eficientes usando as práticas atuais no desenvolvimento. Com uma grande facilidade de aprendizado e espírito de equipe, sou proficiente em uma variedade de linguagens e ferramentas Web e mobile. Apaixonado por trabalhar com Python, Javascript e seus ultimos frameworks e um entusiasta no desenvolvimento mobile com Flutter.",
  // Résumé
  "<p>I am a Developer with 5+ years of experience that have worked with many different technologies and environments.  Experienced with back-end as well as front-end development, I am a professional passionate about creating good solutions and bringing amazing designs to life.</p><p>I started my career working with IT support, which gave me a good experience with infrastructures and network management, improving my DevOps skills. But soon I changed to a developer role, where I started applying and improving my coding skills.</p><p>Throughout my career as a developer, I had worked in many different fields such as: Health Care, Digital Agencies, Software Factories and University. I had the opportunity to architect, develop and maintain web and mobile applications, working with different teams and leading some projects and features.</p>":
    "<p>Sou um desenvolvedor com 5+ anos de experiência que já trabalhou com tecnologias e ambientes diversos. Com boas experiências com Back-end assim como Front-end, sou um profissional apaixonado por criar boas soluções e trazer designs incríveis a vida.</p><p>Comecei a minha carreira como Suporte de TI, o que me deu uma boa experiência com infraestruturas, gerenciamento de redes e boas noções de DevOps. Logo após isso, migrei para a área de desenvolvimento onde comecei a aplicar e melhorar minhas habilidades com código.</p><p>Através da minha carreira como desenvolvedor, pude trabalhar em diferentes áreas como: Saúde, Agencias Digitais, Fábricas de software e Educação. Tive a oportunidade de arquitetar, desenvolver e manter aplicações web a mobile, trabalhando em diferentes times e liderando alguns projetos e funcionalidades.</p>",
  "Software Development and Analysis": "Análise e Desenvolvimento de Sistemas",
  "Alayacare is a Home Care Software company that offers a solution for agencies to manage clients, visits, accounting and many other functionalities. I am responsible for taking care of back-end as well as front-end development, creating new features and improving existing ones.":
    "Alayacare é um software para gerenciamento de Home Care que oferece uma solução para agencias de saúde gerenciar clientes, visitas, finanças e muitas outras funcionalidades. Sou responsável pelo desenvolvimento back-end assim como front-end, criando novas funcionalidades e melhorando funcionalidades existentes.",
  "Sweepy is a mobile app soon to be released with the purpose of ordering food, reserving tables at restaurants and paying for parking in advance. I was responsible for the defining the architecture of the application from scratch, developing the infraestructure, full-stack and mobile development.":
    "Sweepy é um aplicativo mobile com o objetivo de pedir comida online, reservar mesas nos restaurantes e pagar por estacionamento antecipadamente. Fui responsável por definir a arquitetura, desenvolver a infraestrutura, desenvolvimento back-end, mobile e implementações DevOps.",
  "A company focused on automation of all kinds, searching for improving efficiency and speed of current manual tasks replacing them with software automated tasks. I was responsible for creating a mobile app and automating the process of scanning invoices and work orders.":
    "Empresa focada em automações de software de todos os tipos, buscando melhorar a eficiência e velocidade de tarefas atualmente manuais, substituindo-as por tarefas automatizadas em software. Fui responsável pela criação de um app mobile e a automação do processo de escanear notas fiscais e ordens de serviço.",
  Developer: "Desenvolvedor",
  "Full Stack Developer ": "Desenvolvedor Full Stack",
  "The company is a software studio that creates, design and develops web and mobile applications. I worked in a team of designers and developers, where I was responsible for integrating software with third part solutions, develop and maintain web and mobile applications and configure cloud servers. Experience with a variety of technologies, working mostly with javascript and with the following stack: Nodejs, Reactjs, MongoDB and Dart/Flutter.":
    "A empresa é um estúdio de software que cria, projeta e desenvolve aplicações web e mobile. Trabalhei em uma equipe de designers e desenvolvedores, onde fui responsável por integrar software com soluções de terceiros, desenvolver e manter aplicativos da Web e Mobile e configurar servidores em nuvem. Experiência com uma variedade de tecnologias, trabalhando principalmente com javascript e com o seguinte stack: Nodejs, Reactjs, MongoDB e Dart / Flutter.",
  "Web Developer": "Desenvolvedor Web",
  "Santa Cruz is a University in Paraná - Brazil maintained by the Paranaense Union of Education and Culture - UNIPEC. I was responsible for the creation, maintenance and support of web applications and integration with third part services, working close to the marketing team. Responsible for the creation of pages, email marketing and system support, working with PHP, Wordpress, HTML, CSS, Javascript, React.js and MySQL.":
    "Santa Cruz é uma faculdade no Paraná - Brasil mantida pela União Paranaense de Educação e Cultura - UNIPEC. Fui responsável pela criação, manutenção e suporte de aplicações web e integração com serviços de terceiros, trabalhando junto à equipe de marketing. Responsável pela criação de páginas, email marketing e suporte aos sistemas internos, trabalhando com PHP, Wordpress, HTML, CSS, Javascript, React.js e MySQL.",
  "HouseCricket is a brazilian digital agency specialized in digital marketing and communication strategies. I worked as a Web Developer alongside with the design team, responsible for creating and maintaining web applications and email marketing, creating web pages, email marketing templates and managing CMSs, working with PHP, HTML, CSS, Javascript (JQuery, React.js and Vue.js) and MySQL.":
    "HouseCricket é uma agência digital brasileira especializada em marketing digital e estratégias de comunicação. Trabalhei como Desenvolvedor Web juntamente com as equipes de desenvolvimento e design, responsável pela criação e manutenção de aplicações web e email marketing, criação de páginas web, templates de email marketing  e gerenciamento de CMSs, trabalhando com PHP, HTML, CSS, Javascript (JQuery, React.js e Vuew, js) e MySQL.",
  "IT Support Assistant": "Assistente de TI",
  "Opusmultipla is a brazilian marketing agency part of a group called GrupoOM. I worked as an IT Support Assistant, responsible for providing support to computer operations, infrastructure and network management, ensuring that the technology services were delivered smoothly.":
    "Opusmultipla é uma agência de marketing brasileira que faz parte de um grupo chamado GrupoOM. Trabalhei como Assistente de Suporte de TI, responsável por fornecer suporte a operações de computadores, infraestrutura e gerenciamento de redes e servidores, suporte de hardware e software, manutenção de equipamentos, controle de inventário de máquinas e licenciamento de software, atuando com service desk e práticas ITIL.",
  // Footer
  PHONE: "PHONE",
  EMAIL: "E-MAIL",
  "Get in touch": "Entre em contato",
  "or just write me an email here": "ou envie um e-mail aqui",
  "Your name": "Seu nome",
  "Your email": "Seu email",
  "Message...": "Mensagem...",
  "send": "enviar",
  // Blog
  Search: "Search",
  // Comments
  Comments: "Comments",
};
