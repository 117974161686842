<template>
  <header :class="headerClasses">
    <div class="header__branding">
      <a href="/" @click="expandMenu(false)">
        <h1>
          {{ brandName }}
        </h1>
      </a>
    </div>
    <div class="header__navbar">
      <ul :class="navClasses">
        <button class="btn btn__close" @click="expandMenu(false)">
          <IconClose />
        </button>
        <li class="nav__item" v-for="item in items" :key="item.name">
          <a :href="item.url" @click="expandMenu(false)">
            {{ $t(item.label) }}
          </a>
        </li>
        <LanguageButton @change="expandMenu(false)" />
      </ul>
      <button class="btn btn__icon header__menu" @click="expandMenu(!expanded)">
        <IconMenu />
      </button>
    </div>
  </header>
</template>

<script>
import IconClose from "@/assets/icons/close.svg";
import IconMenu from "@/assets/icons/menu.svg";
import LanguageButton from "@/components/buttons/language-button";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    IconClose,
    IconMenu,
    LanguageButton,
  },
  data() {
    return {
      brandName: "<ThiagoPacheco/>",
      expanded: false,
      items: [
        {
          name: "blog",
          label: "Blog",
          url: "/blog",
        },
        {
          name: "home",
          label: "About me",
          url: "/home#hero",
        },
        // {
        //   name: "resume",
        //   label: "Résumé",
        //   url: "/home#resume",
        // },
        {
          name: "portfolio",
          label: "Projects",
          url: "/home#projects",
        },
        {
          name: "contact",
          label: "Contact",
          url: "#footer",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapActions({
      setSticky: "layout/header/setSticky",
    }),
    handleScroll() {
      this.setSticky(window.scrollY > 150);
    },
    expandMenu(expand = true) {
      this.expanded = expand;
    },
  },
  computed: {
    ...mapGetters({
      sticky: "layout/header/sticky",
    }),
    headerClasses() {
      const classes = ["header"];
      if (this.sticky) return [...classes, "sticky"];
      return classes;
    },
    navClasses() {
      const classes = ["header__nav", "nav"];
      if (this.expanded) return [...classes, "nav--expanded"];
      return classes;
    },
  },
};
</script>

<style></style>
