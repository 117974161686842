<template>
  <router-link :to="postUrl" class="blog__item">
    <div class="blog__thumbnail post__thumbnail">
      <img :src="post.cover_image" alt="" loading="lazy" class="post__image"/>
    </div>
    <div class="blog__content post__content">
      <h3 class="post__title">
        {{ post.title }}
      </h3>
      <span class="post__date">
        {{ getFormattedDate(post.created_at) }}
      </span>
      <p class="post__description">
        {{ post.description }}
      </p>
      <span class="post__read">
        Read more
      </span>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    post: {
      required: true,
      type: Object,
    },
  },
  methods: {
    getFormattedDate(dateStr) {
      const d = new Date(dateStr);
      return `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`;
    },
  },
  computed: {
    postUrl() {
      return `/blog/${this.post.id}`;
    },
  },
};
</script>
