<template>
  <div class="home">
    <HeroSection />
    <AboutSection />
    <!-- <ResumeSection /> -->
    <ProjectsSection />
  </div>
</template>

<script>
import HeroSection from "@/sections/hero";
import AboutSection from "@/sections/about";
import ResumeSection from "@/sections/resume";
import ProjectsSection from "@/sections/projects";

export default {
  name: "Home",
  components: {
    HeroSection,
    AboutSection,
    ResumeSection,
    ProjectsSection,
  },
  mounted() {
    this.$ga.page("/home");
  },
};
</script>
