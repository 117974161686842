<template>
  <div class="about" id="about">
    <div class="container about__container">
      <h1 class="heading-1 about__heading">Hi</h1>
      <p class="about__text">
        Staff Software Developer with over 7 years of experience, adept in all
        stages of advanced web development, knowledgeable in system design, user
        interface, testing, and debugging processes. Equipped with a diverse and
        promising skill-set, proficient in an assortment of technologies,
        including Python, JavaScript, MySQL, PostgreSQL, and Docker.
      </p>
      <p class="about__text">
        Passionate about building end-to-end solutions, and thinking about best
        practices and decisions while constantly learning and contributing to
        the development community.
      </p>
      <p class="about__text">
        I strongly believe that teamwork makes the dream work, so I am always
        looking for better ways to collaborate with the team bringing new
        methodologies and activities.
      </p>
      <a href="/files/resume.pdf" class="btn btn__download">
        <IconDownload />
        <span>{{ $t("Download CV") }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import IconDownload from "@/assets/icons/download.svg";

export default {
  components: {
    IconDownload,
  },
  data() {
    return {
      about: {
        greeting: `Hi`,
        // text: `I am Full Stack Developer able to build Web and mobile applications from the ground up - from concept, navigation, layout and programming to UX and SEO. Skilled at writing well-designed, testable and efficient code using current best practices. Fast learner, hard worker and team player who is proficient in an array of languages and tools. Passionate about working with Python, Javascript and its latest frameworks and I am a big enthusiast working with Flutter on mobile development.`,
        // cvUrl: `/api/files?name=CV.pdf`,
      },
    };
  },
};
</script>

<style></style>
