<template>
  <li class="project__item">
    <div
      class="project__thumbnail"
      :style='{
        "background-image": `url("${project.image}")`,
      }'
    >
      <a v-if="project.url" :href="project.url" class="project__thumbnail--details">
        <button class="btn btn__secondary btn__secondary--white">
          {{ $t("View website") }}
        </button>
      </a>
    </div>
    <div class="project__content">
      <a :href="project.url">
        <h4 class="heading-5">
          {{ project.title }}
        </h4>
      </a>
      <p class="project__description">
        {{ project.description }}
      </p>
      <div v-if="project.development" class="project__stack stack">
        <span>DEVELOPMENT:</span>
        <ul class="stack__list">
          <li
            class="stack__item"
            v-for="(devItem, index) in project.development"
            :key="index"
          >
            {{ devItem }}
          </li>
        </ul>
      </div>
      <div v-if="project.stack" class="project__stack stack">
        <span>USED STACK:</span>
        <ul class="stack__list">
          <li class="stack__item" v-for="(stack, i) in project.stack" :key="i">
            {{ stack }}
          </li>
        </ul>
      </div>
      <a :href="project.url" class="project__link">{{ project.url }}</a>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    project: {
      required: true,
      type: Object,
    },
  },
};
</script>
