import Axios from "axios";

export const getInstance = () => {
  return Axios.create({
    baseURL: "https://dev.to/api",
  });
};

export const fetchArticles = async () => {
  const axios = getInstance();
  const res = await axios.get("/articles?username=pacheco");
  return res.data;
};

export const fetchArticle = async (id) => {
  const axios = getInstance();
  const res = await axios.get(`/articles/${id}`);
  return res.data;
};

export const fetchComments = async (article_id) => {
  const axios = getInstance();
  const res = await axios.get(`/comments?a_id=${article_id}`);
  return res.data;
};
