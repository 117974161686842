<template>
  <div class="comments">
    <h1 v-if="showTitle" class="comments__title">
      {{ $t("Comments") }}
    </h1>
    <div class="comments__list">
      <comment
        v-for="comment in comments"
        :key="comment.id_code"
        :comment="comment"
      />
    </div>
  </div>
</template>

<script>
import "./comments.scss";
import Comment from "./comment";

export default {
  name: "comments",
  components: {
    Comment,
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
