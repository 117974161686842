import { getStoredLang, storeLang } from "@/services/languageService";

export default {
  namespaced: true,
  state: () => ({
    language: getStoredLang() || "en",
    languages: ["en", "pt", "fr"],
  }),
  getters: {
    currentLanguage(state) {
      // Todo: Remove hardcoded lang once all languages are updated and ready
      return "en";
      // return state.language;
    },
    getLanguages(state) {
      // Todo: Remove hardcoded lang once all languages are updated and ready
      return ["en"];
      return state.languages;
    },
  },
  mutations: {
    setLanguage(state, language) {
      if (state.languages.includes(language)) {
        state.language = language;
        storeLang(language);
        return;
      }
    },
  },
  actions: {
    setLanguage({ commit }, language) {
      commit("setLanguage", language);
    },
  },
  modules: {
    header: {
      namespaced: true,
      state: () => ({
        sticky: false,
        forceSticky: false,
      }),
      getters: {
        sticky(state) {
          return state.forceSticky || state.sticky;
        },
      },
      mutations: {
        setSticky(state, isSticky = true, force = false) {
          state.sticky = isSticky;
          state.forceSticky = force;
        },
      },
      actions: {
        setSticky({ commit }, isSticky = true, force = false) {
          commit("setSticky", isSticky, force);
        },
      },
    },
  },
};
