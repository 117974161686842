<template>
  <div class="page blog">
    <page-header title="Blog" :description="headerDescription" />
    <div class="container blog__container">
      <ul v-if="filteredArticles.length" class="blog__list">
        <list-item
          v-for="(post, index) in filteredArticles"
          :key="index"
          :post="post"
        />
      </ul>
      <div v-else class="blog__list blog__list--empty">
        {{ $t(message) }}
      </div>
      <aside class="aside blog__sidebar">
        <div class="form__search">
          <input
            type="text"
            name="search"
            :placeholder="$t('Search')"
            v-model="search"
            class="form__input"
            @keyup.enter="applySearch"
          />
        </div>
        <a class="blog__sidebar__dev" href="https://dev.to/pacheco">
          <span>Read on</span>
          <img
            src="https://d2fltix0v2e0sb.cloudfront.net/dev-badge.svg"
            alt="Thiago Pacheco's DEV Community Profile"
            height="30"
            width="30"
          />
        </a>
        <div class="blog__latest">
          <h4 class="latest__title">
            {{ $t("Latest posts") }}
          </h4>
          <ul class="latest__posts">
            <li
              class="latest__post"
              v-for="(post, index) in latestArticles"
              :key="index"
            >
              <router-link :to="getUrl(post.id)" class="latest__post__link">
                {{ post.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
import "./blog.scss";
import PageHeader from "@/components/page-header";
import ListItem from "./list-item";
import { fetchArticles } from "@/services/devService";

export default {
  components: {
    PageHeader,
    ListItem,
  },
  mounted() {
    this.$ga.page("/blog");
  },
  data() {
    return {
      articles: [],
      filteredArticles: [],
      latestArticles: [],
      search: "",
      message: "No articles found",
    };
  },
  methods: {
    async fetchArticles() {
      try {
        const articles = await fetchArticles();
        this.articles = articles;
        this.latestArticles = articles;

        // filter articles
        const search = this.$route?.query?.search;
        if (search) {
          const searches = this.search.toLowerCase().split(" ");
          this.filteredArticles = articles.filter((article) => {
            for (const term of searches) {
              if (
                article.title.toLowerCase().includes(term) ||
                article.description.toLowerCase().includes(term)
              )
                return true;
            }
            return false;
          });
        } else {
          this.filteredArticles = this.articles;
        }
      } catch (error) {
        console.log("error found", error);
      }
    },
    getUrl(id) {
      return `/blog/${id}`;
    },
    applySearch() {
      if (!this.search) {
        this.$router.push({ query: null });
        return;
      }
      let currentSearch = this.$route?.query?.search;
      const searches = this.search.toLowerCase().split(" ");
      if (currentSearch) {
        if (!Array.isArray(currentSearch))
          currentSearch = currentSearch.split(" ");
        if (JSON.stringify(currentSearch) != JSON.stringify(searches)) {
          this.$router.push({ query: { search: searches } });
        }
      } else {
        this.$router.push({ query: { search: searches } });
      }
    },
  },
  computed: {
    headerDescription() {
      let currentSearch = this.$route?.query?.search;
      if (currentSearch) {
        return `Articles with keywords = ${currentSearch}`;
      }
    },
  },
  watch: {
    "$route.query.search": {
      handler: "fetchArticles",
      immediate: true,
    },
  },
};
</script>
