<template>
  <div class="resume" id="resume">
    <div class="container resume__container">
      <h1 class="heading-1 resume__heading">
        {{ $t(resume.title) }}
      </h1>
      <div className="resume__text" v-html="$t(resume.text)" />
      <div class="resume__education">
        <h3 class="heading-3 resume_heading--3">
          {{ $t("Education") }}
        </h3>
        <ul class="resume__list">
          <li
            class="resume__item"
            v-for="(item, index) in resume.education"
            :key="index"
          >
            <h4 class="heading-4">
              {{ item.school }}
            </h4>
            <p class="resume__date">
              {{ `${item.startDate} - ${item.endDate}` }}
            </p>
            <p class="resume__text">
              {{ $t(item.course) }}
            </p>
          </li>
        </ul>
      </div>
      <div class="resume__employment">
        <h3 class="heading-3 resume_heading--3">
          {{ $t("Experience") }}
        </h3>
        <ul class="resume__list">
          <li
            class="resume__item"
            v-for="(item, index) in resume.employment"
            :key="index"
          >
            <h4 class="heading-4">
              {{ item.enterprise }}
            </h4>
            <p class="resume__position">
              {{ $t(item.position) }}
            </p>
            <p class="resume__date">
              {{ `${item.startDate} - ${item.endDate}` }}
            </p>
            <p class="resume__text">
              {{ $t(item.description) }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resume: {
        title: `Résumé`,
        text:
          "<p>I am a Developer with 5+ years of experience that have worked with many different technologies and environments.  Experienced with back-end as well as front-end development, I am a professional passionate about creating good solutions and bringing amazing designs to life.</p><p>I started my career working with IT support, which gave me a good experience with infrastructures and network management, improving my DevOps skills. But soon I changed to a developer role, where I started applying and improving my coding skills.</p><p>Throughout my career as a developer, I had worked in many different fields such as: Health Care, Digital Agencies, Software Factories and University. I had the opportunity to architect, develop and maintain web and mobile applications, working with different teams and leading some projects and features.</p>",
        education: [
          {
            id: 1,
            school: `Faculdades Integradas Camões`,
            course: `Software Development and Analysis`,
            startDate: `2015`,
            endDate: `2018`,
          },
        ],
        employment: [
          {
            id: 12,
            enterprise: `Alayacare`,
            position: `Developer`,
            description: `Alayacare is a Home Care Software company that offers a solution for agencies to manage clients, visits, accounting and many other functionalities. I am responsible for taking care of back-end as well as front-end development, creating new features and improving existing ones.`,
            startDate: `2020`,
            endDate: `Current job`,
          },
          {
            id: 11,
            enterprise: `Matilha Studio`,
            position: `Full Stack Developer`,
            description: `The company is a software studio that creates, design and develops web and mobile applications. I worked in a team of designers and developers, where I was responsible for integrating software with third part solutions, develop and maintain web and mobile applications and configure cloud servers. Experience with a variety of technologies, working mostly with javascript and with the following stack: Nodejs, Reactjs, MongoDB and Dart/Flutter.`,
            startDate: `2019`,
            endDate: `2019`,
          },
          {
            id: 543,
            enterprise: `Sweepy`,
            position: `Full Stack Developer`,
            description: `Sweepy is a mobile app soon to be released with the purpose of ordering food, reserving tables at restaurants and paying for parking in advance. I was responsible for the defining the architecture of the application from scratch, developing the infraestructure, full-stack and mobile development.`,
            startDate: `2019`,
            endDate: `8 month contract`,
          },
          {
            id: 321,
            enterprise: `SmarTI`,
            position: `Full Stack Developer`,
            description: `A company focused on automation of all kinds, searching for improving efficiency and speed of current manual tasks replacing them with software automated tasks. I was responsible for creating a mobile app and automating the process of scanning invoices and work orders.`,
            startDate: `2019`,
            endDate: `2 month contract`,
          },
          {
            id: 1,
            enterprise: `Faculdades Santa Cruz`,
            position: `Web Developer`,
            description: `Santa Cruz is a University in Paraná - Brazil maintained by the Paranaense Union of Education and Culture - UNIPEC. I was responsible for the creation, maintenance and support of web applications and integration with third part services, working close to the marketing team. Responsible for the creation of pages, email marketing and system support, working with PHP, Wordpress, HTML, CSS, Javascript, React.js and MySQL.`,
            startDate: `2018`,
            endDate: `2019`,
          },
          {
            id: 2,
            enterprise: `Grupo OM | HouseCricket`,
            position: `Web Developer`,
            description: `HouseCricket is a brazilian digital agency specialized in digital marketing and communication strategies. I worked as a Web Developer alongside with the design team, responsible for creating and maintaining web applications and email marketing, creating web pages, email marketing templates and managing CMSs, working with PHP, HTML, CSS, Javascript (JQuery, React.js and Vue.js) and MySQL.`,
            startDate: `2017`,
            endDate: `2018`,
          },
          {
            id: 3,
            enterprise: `Grupo OM | Opusmúltipla`,
            position: `IT Support Assistant`,
            description: `Opusmultipla is a brazilian marketing agency part of a group called GrupoOM. I worked as an IT Support Assistant, responsible for providing support to computer operations, infrastructure and network management, ensuring that the technology services were delivered smoothly.`,
            startDate: `2015`,
            endDate: `2017`,
          },
        ],
      },
    };
  },
};
</script>
