<template>
  <li class="nav__item">
    <button class="btn btn__language" @click="expandMenu(!expanded)">
      {{ currentLanguage }}
    </button>
    <ul v-if="expanded" class="language__list">
      <li
        class="language__item"
        v-for="(language, index) in languages"
        :key="index"
        @click="setLanguage(language)"
      >
        {{ language }}
      </li>
    </ul>
  </li>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    ...mapActions({
      setLang: "layout/setLanguage",
    }),
    expandMenu(expanded = false) {
      this.expanded = expanded;
    },
    setLanguage(language) {
      this.setLang(language);
      this.expandMenu(false);
      this.$emit("change");
    },
  },
  computed: {
    ...mapGetters({
      currentLanguage: "layout/currentLanguage",
      languages: "layout/getLanguages",
    }),
  },
};
</script>

<style></style>
