<template>
  <div class="article">
    <PageHeader :title="title" :background-image="coverImage" />
    <div v-if="article" class="container article__container">
      <article class="article__content">
        <img class="article__image" :src="article.cover_image" alt="" />
        <vue-markdown>{{ article.body_markdown }}</vue-markdown>
        <comments
          :key="Math.random()"
          v-if="comments"
          :comments="comments"
          :show-title="true"
        />
      </article>
      <aside class="article__sidebar">
        <div class="form__search">
          <input
            type="text"
            name="search"
            :placeholder="$t('Search')"
            v-model="search"
            class="form__input"
          />
        </div>
        <a class="blog__sidebar__dev" href="https://dev.to/pacheco">
          <span>Read on</span>
          <img
            src="https://d2fltix0v2e0sb.cloudfront.net/dev-badge.svg"
            alt="Thiago Pacheco's DEV Community Profile"
            height="30"
            width="30"
          />
        </a>
        <div class="blog__latest">
          <h4 class="latest__title">
            {{ $t("Latest posts") }}
          </h4>
          <ul class="latest__posts">
            <li
              class="latest__post"
              v-for="(post, index) in latestArticles"
              :key="index"
            >
              <router-link :to="getUrl(post.id)" class="latest__post__link">
                {{ post.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
import "./article.scss";
import {
  fetchArticle,
  fetchArticles,
  fetchComments,
} from "@/services/devService";
import PageHeader from "@/components/page-header";
import Comments from "@/components/comments";
import VueMarkdown from "vue-markdown";

export default {
  components: {
    PageHeader,
    VueMarkdown,
    Comments,
  },
  data() {
    return {
      article: null,
      loading: true,
      search: "",
      latestArticles: [],
      comments: [],
    };
  },
  methods: {
    async fetchData() {
      await this.fetchArticle();
      await this.fetchArticles();
      await this.fetchComments();
      this.$ga.page(this.$route.path);
    },
    async fetchArticle() {
      console.log("fetch items");
      try {
        const article = await fetchArticle(this.$route.params.id);
        console.log("item found", article);
        this.article = article;
        this.loading = false;
      } catch (error) {
        console.log("Failed fetching article");
      }
    },
    async fetchArticles() {
      try {
        const articles = await fetchArticles();
        this.latestArticles = articles;
      } catch (error) {
        console.log("Failed fetching article");
      }
    },
    async fetchComments() {
      try {
        this.comments = await fetchComments(this.$route.params.id);
        console.log("comments found", this.comments);
      } catch (error) {
        console.log("Failed fetching comments");
      }
    },
    getUrl(id) {
      return `/blog/${id}`;
    },
  },
  computed: {
    title() {
      return this.article?.title || "";
    },
    coverImage() {
      return this.article?.cover_image || "";
    },
  },
  watch: {
    "$route.params.id": {
      handler: "fetchData",
      immediate: true,
    },
  },
};
</script>
