<template>
  <div class="projects" id="projects">
    <div class="container projects__container">
      <h3 class="heading-1 projects__heading">
        {{ $t("Projects") }}
      </h3>
      <ul class="project__list">
        <Project
          v-for="(project, index) in projects"
          :key="index"
          :project="project"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import Project from "./project";

export default {
  components: {
    Project,
  },
  data() {
    return {
      projects: [
        {
          id: 1,
          title: `Conecta Mars`,
          description: `Sales campaign management application, with the purpose of assisting promoters at submitting information and photos for evaluation that may compete for prizes.`,
          image: `https://res.cloudinary.com/simplecoder/image/upload/v1613446428/portfolio/conecta.webp`,
          development: [`Mobile`, `Front end`, `Back end`],
          stack: [`Dart`, `Flutter`, `Nodejs`, `MongoDB`, `Redis`],
          url: `https://play.google.com/store/apps/details?id=digital.matilha.mars_app&hl=pt_BR`,
        },
        {
          id: 123,
          title: "Sweepy app",
          description: `Sweepy is a mobile app to order food, similar to UberEats and DoorDash but focused on take out orders. It also contains tools for reserving table in the restaurants and paying for parking in advance.`,
          image: `https://res.cloudinary.com/simplecoder/image/upload/v1613518538/portfolio/sweepy_bg.png`,
          development: [`Mobile`, `Front end`, `Back end`],
          stack: [
            `Flutter`,
            `Node.js`,
            `Vue.js`,
            `Typescript`,
            `MongoDB`,
            `Redis`,
            `Docker`,
            `Kubernetes`,
          ],
          url: null,
          notAvailable: `App still not available, will be launched soon.`,
        },
        {
          id: 321321,
          title: `Ocyan app`,
          description: `A mobile app developed to Ocyan Waves with the objective of automating the process of scanning invoices and work orders, processing its contents and sending the information via email to the correct recipients.`,
          image: `https://res.cloudinary.com/simplecoder/image/upload/v1613518766/portfolio/New_Project.png`,
          development: [`Mobile`, `Back end`, `Automation`],
          stack: [`Flutter`, `Node.js`, `Java`, `Spring`, `Puppeteer`],
          url: null,
          notAvailable: `Business app distributed only for the company employees`,
        },
        {
          id: 541,
          title: `Mondelez Campaign Manager`,
          description: `A web app built to manage supermarket campaigns, assisting promoters at submitting information and photos for evaluation that may compete for prizes. It also manages big data reports of these campaigns, with custom actions and configurations.`,
          image: `https://res.cloudinary.com/simplecoder/image/upload/v1613518554/portfolio/Screen_Shot_2021-02-16_at_18.35.04.png`,
          development: [`Front end`, `Back end`],
          stack: [
            `Node.js`,
            `React.js`,
            `Express.js`,
            `MongoDB`,
            `MySQL`,
            `Redis`,
            `Docker`,
          ],
          url: null,
        },
        {
          id: 653,
          title: `Neoo`,
          description: `A web app built to rent rooms for business services. It manages renting rooms per hour or daily.`,
          image: `https://res.cloudinary.com/simplecoder/image/upload/v1613518538/portfolio/neoo_work_bg.png`,
          development: [`Front end`, `Back end`],
          stack: [`Ruby`, `Rails`, `MySQL`, `Docker`],
          url: `https://neoo.work`,
        },
        {
          id: 2,
          title: `Friminho`,
          description: `Website created to present Friminho, a line of food products from Frimesa aimed at children.`,
          image: `https://res.cloudinary.com/simplecoder/image/upload/v1613446428/portfolio/friminho.jpg`,
          development: [`Back end`, `Front end`],
          stack: [`PHP`, `Codeigniter`, `MySQL`, `Pug`, `Sass`, `Javascript`],
          url: `http://friminho.com.br`,
        },
        {
          id: 3,
          title: `Eletropaulo nas Escolas`,
          description: `A Web system and Website that promotes the conscious use of water and electric energy through the mobilization of educators and students.`,
          image: `https://res.cloudinary.com/simplecoder/image/upload/v1613446428/portfolio/eletropaulo.jpg`,
          development: [`Back end`],
          stack: [`PHP`, `Codeigniter`, `MySQL`],
          url: `http://eletropaulonasescolas.com.br`,
        },
        {
          id: 4,
          title: `Santa Cruz`,
          description: `Website with the objective of presenting the Faculty Santa Cruz, presenting teaching contents, guides on how to enter, access to internal systems for students and much more.`,
          image: `https://res.cloudinary.com/simplecoder/image/upload/v1613446429/portfolio/santacruz.jpg`,
          development: [`Front end`, `Back end`, `Design`],
          stack: [`Wordpress`, `HTML`, `CSS`, `Sass`, `Javascript`, `React.js`],
          url: `http://santacruz.br`,
        },
      ],
    };
  },
};
</script>
