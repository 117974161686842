<template>
  <section class="page-header" :style="pageHeaderStyles">
    <div class="container">
      <h1 class="page-header__title">
        {{ title }}
      </h1>
      <p v-if="description" class="page-header__description">
        {{ description }}
      </p>
    </div>
  </section>
</template>

<script>
import "./page-header.scss";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    backgroundImage: {
      type: String,
    },
  },
  computed: {
    pageHeaderStyles() {
      return {
        "background-image": `linear-gradient(to right bottom, rgba(16, 26, 38, .9), rgba(42, 55, 64, 0.88)), url("${this.backgroundImage}")`,
      };
    },
  },
};
</script>
