<template>
  <div class="layout">
    <Header />
    <main class="main">
      <router-view />
    </main>
    <Footer />
  </div>
</template>

<script>
import Vue from "vue";
import Header from "./header";
import Footer from "./footer";
import { mapGetters } from "vuex";
import { getStoredLang, storeLang } from "@/services/languageService";

export default {
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapGetters({
      currentLanguage: "layout/currentLanguage",
    }),
  },
  watch: {
    currentLanguage: {
      handler: function(value, oldVal) {
        Vue.i18n.set(value);
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
