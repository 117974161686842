export default {
  // Header
  Home: "Home",
  Résumé: "Résumé",
  Portfolio: "Portfolio",
  Contact: "Contact",
  // Hero
  age: "age",
  phone: "phone",
  email: "email",
  "FullStack Developer | Mobile Developer": "FullStack Developer | Mobile Developer",
  // About
  "Download CV": "Download CV",
  Hi: "Hi",
  "I am Full Stack Developer able to build Web and mobile applications from the ground up - from concept, navigation, layout and programming to UX and SEO. Skilled at writing well-designed, testable and efficient code using current best practices. Fast learner, hard worker and team player who is proficient in an array of languages and tools. Passionate about working with Python, Javascript and its latest frameworks and I am a big enthusiast working with Flutter on mobile development.":
    "I am Full Stack Developer able to build Web and mobile applications from the ground up - from concept, navigation, layout and programming to UX and SEO. Skilled at writing well-designed, testable and efficient code using current best practices. Fast learner, hard worker and team player who is proficient in an array of languages and tools. Passionate about working with Python, Javascript and its latest frameworks and I am a big enthusiast working with Flutter on mobile development.",
  // Résumé
  "<p>I am a Developer with 5+ years of experience that have worked with many different technologies and environments.  Experienced with back-end as well as front-end development, I am a professional passionate about creating good solutions and bringing amazing designs to life.</p><p>I started my career working with IT support, which gave me a good experience with infrastructures and network management, improving my DevOps skills. But soon I changed to a developer role, where I started applying and improving my coding skills.</p><p>Throughout my career as a developer, I had worked in many different fields such as: Health Care, Digital Agencies, Software Factories and University. I had the opportunity to architect, develop and maintain web and mobile applications, working with different teams and leading some projects and features.</p>":
    "<p>I am a Developer with 5+ years of experience that have worked with many different technologies and environments.  Experienced with back-end as well as front-end development, I am a professional passionate about creating good solutions and bringing amazing designs to life.</p><p>I started my career working with IT support, which gave me a good experience with infrastructures and network management, improving my DevOps skills. But soon I changed to a developer role, where I started applying and improving my coding skills.</p><p>Throughout my career as a developer, I had worked in many different fields such as: Health Care, Digital Agencies, Software Factories and University. I had the opportunity to architect, develop and maintain web and mobile applications, working with different teams and leading some projects and features.</p>",
  "Software Development and Analysis": "Software Development and Analysis",
  "Alayacare is a Home Care Software company that offers a solution for agencies to manage clients, visits, accounting and many other functionalities. I am responsible for taking care of back-end as well as front-end development, creating new features and improving existing ones.":
    "Alayacare is a Home Care Software company that offers a solution for agencies to manage clients, visits, accounting and many other functionalities. I am responsible for taking care of back-end as well as front-end development, creating new features and improving existing ones.",
  "Sweepy is a mobile app soon to be released with the purpose of ordering food, reserving tables at restaurants and paying for parking in advance. I was responsible for the defining the architecture of the application from scratch, developing the infraestructure, full-stack and mobile development.":
    "Sweepy is a mobile app soon to be released with the purpose of ordering food, reserving tables at restaurants and paying for parking in advance. I was responsible for the defining the architecture of the application from scratch, developing the infraestructure, full-stack and mobile development.",
  "A company focused on automation of all kinds, searching for improving efficiency and speed of current manual tasks replacing them with software automated tasks. I was responsible for creating a mobile app and automating the process of scanning invoices and work orders.":
    "A company focused on automation of all kinds, searching for improving efficiency and speed of current manual tasks replacing them with software automated tasks. I was responsible for creating a mobile app and automating the process of scanning invoices and work orders.",
  Developer: "Developer",
  "Full Stack Developer ": "Full Stack Developer",
  "The company is a software studio that creates, design and develops web and mobile applications. I worked in a team of designers and developers, where I was responsible for integrating software with third part solutions, develop and maintain web and mobile applications and configure cloud servers. Experience with a variety of technologies, working mostly with javascript and with the following stack: Nodejs, Reactjs, MongoDB and Dart/Flutter.":
    "The company is a software studio that creates, design and develops web and mobile applications. I worked in a team of designers and developers, where I was responsible for integrating software with third part solutions, develop and maintain web and mobile applications and configure cloud servers. Experience with a variety of technologies, working mostly with javascript and with the following stack: Nodejs, Reactjs, MongoDB and Dart/Flutter.",
  "Web Developer": "Web Developer",
  "Santa Cruz is a University in Paraná - Brazil maintained by the Paranaense Union of Education and Culture - UNIPEC. I was responsible for the creation, maintenance and support of web applications and integration with third part services, working close to the marketing team. Responsible for the creation of pages, email marketing and system support, working with PHP, Wordpress, HTML, CSS, Javascript, React.js and MySQL.":
    "Santa Cruz is a University in Paraná - Brazil maintained by the Paranaense Union of Education and Culture - UNIPEC. I was responsible for the creation, maintenance and support of web applications and integration with third part services, working close to the marketing team. Responsible for the creation of pages, email marketing and system support, working with PHP, Wordpress, HTML, CSS, Javascript, React.js and MySQL.",
  "HouseCricket is a brazilian digital agency specialized in digital marketing and communication strategies. I worked as a Web Developer alongside with the design team, responsible for creating and maintaining web applications and email marketing, creating web pages, email marketing templates and managing CMSs, working with PHP, HTML, CSS, Javascript (JQuery, React.js and Vue.js) and MySQL.":
    "HouseCricket is a brazilian digital agency specialized in digital marketing and communication strategies. I worked as a Web Developer alongside with the design team, responsible for creating and maintaining web applications and email marketing, creating web pages, email marketing templates and managing CMSs, working with PHP, HTML, CSS, Javascript (JQuery, React.js and Vue.js) and MySQL.",
  "IT Support Assistant": "IT Support Assistant",
  "Opusmultipla is a brazilian marketing agency part of a group called GrupoOM. I worked as an IT Support Assistant, responsible for providing support to computer operations, infrastructure and network management, ensuring that the technology services were delivered smoothly.":
    "Opusmultipla is a brazilian marketing agency part of a group called GrupoOM. I worked as an IT Support Assistant, responsible for providing support to computer operations, infrastructure and network management, ensuring that the technology services were delivered smoothly.",
  // Footer
  PHONE: "PHONE",
  EMAIL: "EMAIL",
  "Get in touch": "Get in touch",
  "or just write me an email here": "or just write me an email here",
  "Your name": "Your name",
  "Your email": "Your email",
  "Message...": "Message...",
  "send": "send",
  // Blog
  Search: "Search",
  // Comments
  Comments: "Comments",
};
