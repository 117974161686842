import Vue from "vue";
import Vuex from "vuex";

import vuexI18n from "vuex-i18n";

import translationsEn from "@/locale/en";
import translationsPt from "@/locale/pt";
import translationsFr from "@/locale/fr";

import layout from "./layout";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    layout,
  },
});

Vue.use(vuexI18n.plugin, store);
Vue.i18n.add("en", translationsEn);
Vue.i18n.add("pt", translationsPt);
Vue.i18n.add("fr", translationsFr);

export default store;
