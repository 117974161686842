<template>
  <div class="comment">
    <div class="comment__header">
      <div class="comment__image" :style="commmentStyles" />
      <div class="comment__name">
        {{ comment.user.name }}
      </div>
      <div class="comment__date">
        {{ getFormattedDate(comment.created_at) }}
      </div>
    </div>
    <div class="comment__body" v-html="comment.body_html" />
    <div class="comments__list">
      <comment v-for="c in comment.children" :key="c.id_code" :comment="c" />
    </div>
  </div>
</template>

<script>
import "./comment.scss";
import { getFormattedDate } from "@/helpers/utils";
import Comments from "../index";

export default {
  name: "comment",
  components: {
    Comments,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getFormattedDate(dateStr) {
      return getFormattedDate(dateStr);
    },
  },
  computed: {
    commmentStyles() {
      return {
        "background-image": `url("${this.comment.user.profile_image}")`,
      };
    },
  },
};
</script>
