<template>
  <div class="hero" id="hero" :style="heroStyles">
    <div class="container hero__container">
      <div class="hero__thumbnail" :style="thumbnailStyles" />
      <div class="hero__content">
        <h1 class="hero__name heading-1">{{ hero.name }}</h1>
        <p class="hero__role">{{ $t(hero.role) }}</p>
        <p class="hero__text" v-for="(info, index) in hero.info" :key="index">
          <span class="hero__text--label">{{ $t(info.label) }}: </span>
          {{ info.value }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hero: {
        name: "Thiago Pacheco",
        role: "Staff Software Developer",
        info: [
          {
            label: "age",
            value: 28,
          },
          //{
          //label: "phone",
          //value: "+1 514 850 1467",
          //},
          {
            label: "email",
            value: "hi@pacheco.io",
          },
        ],
      },
      backgroundImage:
        "https://res.cloudinary.com/simplecoder/image/upload/v1614048096/portfolio/WhatsApp_Image_2021-02-22_at_9.37.59_PM.jpg",
      thumbnail:
        "https://res.cloudinary.com/simplecoder/image/upload/v1614559500/portfolio/WhatsApp_Image_2021-02-28_at_7.43.02_PM.jpg",
    };
  },
  computed: {
    heroStyles() {
      return {
        "background-image": `linear-gradient(to right bottom, rgba(16, 26, 38, .9), rgba(42, 55, 64, 0.88)), url("${this.backgroundImage}")`,
      };
    },
    thumbnailStyles() {
      return {
        "background-image": `url("${this.thumbnail}")`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./hero";
</style>
